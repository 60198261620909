import { useState } from 'react';
import { useTranslation } from '../../lib/i18n';

export const Error = ({
  children = undefined,
  className = '',
  title = undefined,
  message = undefined,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(true);

  if (!title) {
    title = t('genericError');
  }

  const onClose = () => {
    setError(false);
  };

  if (!error) {
    return <></>;
  }

  return (
    <div
      role='alert'
      className={`${className} my-8 rounded-lg bg-red1 px-4 py-4 text-base text-white`}
    >
      <a
        role='button'
        onClick={onClose}
        className='float-right cursor-pointer text-xl leading-4'
        aria-label={t('Schliessen')}
      >
        ×
      </a>

      <p className='mb-2 font-bold'>{title}</p>
      <p className='mb-2'>
        {message ? (
          <>{message}</>
        ) : (
          <>
            {t('tryAgain')} {t('contactSupport')}{' '}
            <a className='underline' href='mailto:info@edvinweine.ch '>
              info@edvinweine.ch
            </a>
            .
          </>
        )}
      </p>
      {children && <div>{children}</div>}
    </div>
  );
};
