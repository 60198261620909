import { Formik } from 'formik';
import Link from 'next/link';
import * as Yup from 'yup';
import { IS_DEFAULT_CHANNEL } from '../../lib/config';
import { useTranslation } from '../../lib/i18n';
import { trackEvent } from '../../lib/tracking';
import { Button } from '../common/button';
import { PasswordInput, TextInput } from './fields';
import { IntegraleButton, SocialAuthButton } from './social-buttons';

const LoginForm = ({
  signIn,
  className = '',
  setOnRegister = null,
  description = undefined,
  heading = undefined,
  children = null,
}) => {
  const { t } = useTranslation();

  if (!description) {
    description = t('loginDescription');
  }

  if (!heading) {
    heading = t('login');
  }

  return (
    <>
      <h1 className='mb-2 text-center'>{heading}</h1>
      <p className='mb-6 text-center'>{description}</p>

      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          password: Yup.string().required(t('required')),
          email: Yup.string().email(t('invalidEmail')).required(t('required')),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          if (await signIn(values)) {
            trackEvent({
              category: 'form',
              action: 'submit',
              label: 'login',
            });
          } else {
            setErrors({ password: t('invalidCredentials') });
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={className}>
            <TextInput
              className='mt-0'
              name='email'
              autoComplete='email'
              type='email'
              label={t('email')}
            />

            <PasswordInput name='password' />

            <Link href='/reset' className='link mt-3 block text-center'>
              {t('passwordReset')}
            </Link>

            <Button
              wide
              filled
              loading={isSubmitting}
              type='submit'
              className='mb-3 mt-3'
            >
              {t('Anmelden')}
            </Button>

            {setOnRegister && (
              <a
                className='block cursor-pointer text-center text-sm text-green'
                onClick={setOnRegister}
              >
                {t('noProfile')}
              </a>
            )}
            {IS_DEFAULT_CHANNEL ? (
              <SocialAuthButton border={!children} />
            ) : (
              <IntegraleButton border={!children} />
            )}

            {children && <>{children}</>}
          </form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
