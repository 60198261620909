import { signIn } from 'next-auth/react';
import fbSvg from '../../img/auth/fb-light.svg';
import googleSvg from '../../img/auth/google-light.svg';
import integraleSvg from '../../img/auth/integrale-logo.svg';
import { useTranslation } from '../../lib/i18n';

const icons = [
  { k: 'google', v: googleSvg },
  { k: 'facebook', v: fbSvg },
  // { k: 'instagram', v: instaSvg },
];

export const SocialAuthButton = ({ border = true }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`mt-6 flex flex-col gap-4 ${
        border ? 'border-t border-0.3 pt-8' : ''
      }`}
    >
      {icons.map(({ k, v }) => (
        <a
          role='button'
          key={k}
          className='flex justify-center rounded-md border border-input border-opacity-70 px-3 py-2 transition duration-200 hover:bg-black hover:text-white'
          onClick={() => signIn(k)}
        >
          <img className='' src={v} alt='' />
          <span className='ml-2'>{t('social_auth_' + k)}</span>
        </a>
      ))}
    </div>
  );
};

export const IntegraleButton = ({ border = true }) => {
  const { t } = useTranslation();
  return (
    <div className={`mt-6 ${border ? 'border-t border-0.3 pt-6' : ''}`}>
      <p className='mb-6 text-center'>{t('integrale_text')}</p>
      <a
        href={t('integrale_url')}
        target='_blank'
        rel='noopener noreferrer'
        aria-label={t('integrale_btn')}
        role='button'
        className='max-w-[200px] mx-auto flex justify-center rounded-md border border-green px-3 py-2 transition duration-200 hover:bg-green hover:text-white'
      >
        <img className='w-[120px]' src={integraleSvg} alt='' />
      </a>
    </div>
  );
};
